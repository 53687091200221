import cx from "classnames";
import Image from "next/image";

import { useSettings } from "shared/SettingsProvider/useSettings";
import { useI18n } from "utils/with-i18n.utils";

export const BannerNav = () => {
  const { isBlackFriday } = useSettings();
  const i18n = useI18n();

  return (
    <div
      className={cx(
        "flex items-center justify-evenly text-center min-h-[2rem] max-w-full text-xs lg:text-sm border-b-[1px] lg:border-b-0 overflow-hidden",
        isBlackFriday
          ? "bg-christmas border-y-white h-12 lg:bg-snow lg:bg-cover lg:bg-blend-screen"
          : "bg-yellow border-y-black",
      )}
    >
      {isBlackFriday && (
        <div className="mix-blend-luminosity lg:translate-x-[-50%]">
          <Image
            src="/images/christmas/gift-parachute.webp"
            alt="christmas gift"
            width={72}
            height={72}
            className="object-contain mix-blend-luminosity rotate-[-14deg] lg:scale-125"
          />
        </div>
      )}

      {/* DESKTOP */}
      <div
        className={cx("hidden lg:block", isBlackFriday && "bg-christmas py-6")}
      >
        {i18n.t(
          isBlackFriday ? "banner.blackFridayContent" : "banner.content",
          {},
          { html: true },
        )}
      </div>

      {/* MOBILE */}
      <div
        className={cx("block lg:hidden", isBlackFriday && "bg-christmas py-6")}
      >
        {i18n.t(
          isBlackFriday
            ? "banner.blackFridayContentMobile"
            : "banner.contentMobile",
          {},
          { html: true },
        )}
      </div>

      {isBlackFriday && (
        <div className="mix-blend-luminosity lg:translate-x-[150%]">
          <Image
            src="/images/christmas/gift-parachute.webp"
            alt="christmas gift"
            width={72}
            height={72}
            className="object-contain mix-blend-luminosity rotate-[18deg] lg:scale-125"
          />
        </div>
      )}
    </div>
  );
};
